/**
 *  项目的配置文件
 */
// export const apiUrl = 'http://125.46.14.154:8001/';//接口请求地址
export const apiUrl = 'http://hgmeet.trxxkj.cn/';//接口请求地址
// export const apiUrl = 'http://172.19.4.94:8001/';//接口请求测试地址
export const curLang = 'zh';//当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名
export const chatUrl = 'ws://125.46.14.154:9901/'//客服地址
// export const chatUrl = 'ws://172.19.4.94:9901/'//客服测试地址
export const mUrl = 'http://125.46.14.154:9700/'//移动端网页地址
export const title = '灰谷商城';//浏览器顶部title
export const gdKey = 'b563215e5a4ba74b60a6e24f2a336f63';//高德web-js key
export const statShowDebug = false;//是否开启统计的调试

// ** copyright *** slodon *** version-v3.7 *** date-2022-02-18 ***主版本v3.7



